import React, { createContext } from 'react';

type PropsTonnageContext = {
  tonnage: number;
  setTonnage: React.Dispatch<React.SetStateAction<number>>;
}

const DEFAULT_VALUE = {
  tonnage: 0,
  setTonnage: () => {},
}

export const Context = createContext<PropsTonnageContext>(DEFAULT_VALUE);