import { FC, useContext, useEffect, useState } from "react";
import downArrow from "../../assets/down-arrow.svg";
import {
  MaplinkApiResponse,
  ServiceTypes,
  TollLocation,
  VehicleType,
} from "../../types/globalTypes";
import { Context } from "../../utils/context";
import {
  formatDistance,
  formatPrice,
  formatTime,
  vehicleTypeToAxesConverter,
} from "../../utils/formatters";
import { calculateFreight } from "../../utils/utils";
import styles from "./ShippingResults.module.css";

interface ShippingResultsProps {
  routeData: MaplinkApiResponse | null;
  isTollsListOpen: boolean;
  onToggle: () => void;
}

const ShippingResults: FC<ShippingResultsProps> = ({
  routeData,
  isTollsListOpen,
  onToggle,
}) => {
  const [freightCost, setFreightCost] = useState<number>(0);
  const { tonnage } = useContext(Context);
  const tolls: TollLocation[] =
    routeData?.route?.legs?.[0]?.tollCalculation?.tolls || [];

  useEffect(() => {
    if (routeData) {
      const vehicleType = routeData.route?.legs?.[0].tollCalculation
        .vehicleType as VehicleType;
      const distance = routeData.route?.legs?.[0].distance;

      const freight = calculateFreight(vehicleType, distance);
      setFreightCost(freight);
    }
  }, [routeData]);

  const renderTitle = () => {
    const numberOfAxes = vehicleTypeToAxesConverter(
      routeData?.route?.legs?.[0].tollCalculation.vehicleType
    );

    return <div className={styles["title"]}>Rota com {numberOfAxes} eixos</div>;
  };

  const getTollId = (toll: TollLocation): string => {
    const viaFacil = toll.serviceTypes.find(
      (service: ServiceTypes) => service.name === "Via Facil"
    );
    return viaFacil ? viaFacil.serviceId : "";
  };

  return (
    <div className={styles.container}>
      <div className={styles["toll-container"]}>
        {renderTitle()}
        <div className={styles["summary-table"]}>
          <div className={styles["row"]}>
            <div className={styles["row-key"]}>Distância</div>
            <div className={styles["row-value"]}>
              {formatDistance(routeData?.route?.totalDistance || 0)}
            </div>
          </div>
          <div className={styles["row"]}>
            <div className={styles["row-key"]}>Tempo</div>
            <div className={styles["row-value"]}>
              {formatTime(routeData?.route?.totalNominalDuration || 0)}
            </div>
          </div>
          <div className={styles["row"]}>
            <div className={styles["row-key"]}>Pedágio</div>
            <div className={styles["row-value"]}>
              {formatPrice(routeData?.route?.tollCosts || 0)}
            </div>
          </div>
          <div className={styles["row"]}>
            <div className={styles["row-key"]}>R$/t</div>
            <div className={styles["row-value"]}>
              { tonnage > 0 ? ((routeData ? routeData?.route?.tollCosts + freightCost : 0) / tonnage).toFixed(2) : 0}
            </div>
          </div>
          <div className={styles["row"]}>
            <div className={styles["row-key"]}>Frete Total</div>
            <div className={styles["row-value"]}>
              {formatPrice(
                routeData ? routeData?.route?.tollCosts + freightCost : 0
              )}
            </div>
          </div>
        </div>
        <div className={styles["tolls-table"]}>
          <div onClick={onToggle}>
            <div className={styles["toll-row"]}>Praças de Pedágio</div>
            <img
              src={downArrow}
              alt="down arrow"
              className={`${styles.arrow} ${
                isTollsListOpen ? styles.rotated : ""
              }`}
            />
          </div>
          {isTollsListOpen &&
            tolls.map((toll) => {
              const currentTollId = getTollId(toll);
              return (
                <div key={toll.name} className={styles["toll-row"]}>
                  <div className={styles["toll-row-key-pair"]}>
                    <div className={styles["toll-row-key-name"]}>
                      {toll.name.replace("Pedágio - ", "")}
                    </div>
                    <div className={styles["toll-row-key-location"]}>
                      {toll.address}
                    </div>
                  </div>
                  {currentTollId && (
                    <div className={styles["toll-row-via-facil-id"]}>
                      Código Via Fácil: {currentTollId}
                    </div>
                  )}
                  <div className={styles["toll-row-value"]}>
                    {formatPrice(toll.price)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ShippingResults;
