import styles from "../../CustomForm.module.css";

interface AxesDropdownProps {
  value: number;
  onChange: (value: number) => void;
}

const options = [
  {
    value: 6,
    text: '6',
  },
  {
    value: 9,
    text: '9',
  },
  {
    value: 0,
    text: '6 e 9',
  },
]

const AxesDropdown: React.FC<AxesDropdownProps> = ({ value, onChange }) => {
  return (
    <select
      id="axes"
      name="axes"
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      className={styles.axes}
    >
      {options.map((route) => (
        <option key={route.value} value={route.value}>
          {route.text}
        </option>
      ))}
    </select>
  );
};

export default AxesDropdown;
