import { FC, useState } from "react";
import styles from "./App.module.css";
import CustomForm from "./components/CustomForm";
import Header from "./components/Header";
import Map from "./components/Map/index";
import ShippingResults from "./components/ShippingResults";
import StoredRoutes from "./components/StoredRoutes";
import {
  CurrentViewType,
  MaplinkApiResponse,
  StoredRoutesResponse,
} from "./types/globalTypes";
import Spinner from "./utils/Spinner";
import { Context } from "./utils/context";
import { vehicleTypeToAxesConverter } from "./utils/formatters";

const App: FC = () => {
  const [routeData, setRouteData] = useState<MaplinkApiResponse[] | null>(null);
  const [tonnage, setTonnage] = useState<number>(0);
  const [currentView, setCurrentView] = useState<CurrentViewType>(
    CurrentViewType.SHIPPING
  );
  const [areRoutesUpdated, setAreRoutesUpdated] = useState<boolean>(false);
  const [storedRoutesList, setStoredRoutesList] = useState<
    StoredRoutesResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openTollsList, setOpenTollsList] = useState<number | null>(null);

  const handleRouteDataChange = (
    newRouteData: MaplinkApiResponse[] | null
  ): void => {
    setRouteData(newRouteData);
  };

  const renderSpinner = () => {
    return (
      <div className={styles["loading-overlay"]}>
        <Spinner
          style={{
            position: "absolute",
            margin: "auto",
            display: "block",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          size={150}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Context.Provider value={{ tonnage, setTonnage }}>
        <Header />
        <div className={styles["button-group"]}>
          <button
            onClick={() => {
              setCurrentView(CurrentViewType.SHIPPING);
            }}
            className={styles["page-button"]}
          >
            Pesquisar Rota
          </button>
          <button
            onClick={() => {
              setCurrentView(CurrentViewType.STORAGE);
            }}
            className={styles["page-button"]}
          >
            Cadastrar Rotas
          </button>
        </div>
        <div className={styles["internal-container"]}>
          <CustomForm
            formType={currentView}
            onRouteChange={handleRouteDataChange}
            onLoadingStart={() => setIsLoading(true)}
            onLoadingEnd={() => setIsLoading(false)}
            setAreRoutesUpdated={setAreRoutesUpdated}
            onStoredRouteSuccess={() => {
              setAreRoutesUpdated(true);
            }}
          />
          {currentView === "shipping" ? (
            <div className={styles["shipping-results-container"]}>
              <div className={styles["summary-tables"]}>
                {routeData?.sort((a, b) => vehicleTypeToAxesConverter(
                  a?.route?.legs?.[0].tollCalculation.vehicleType
                ) - vehicleTypeToAxesConverter(
                  b?.route?.legs?.[0].tollCalculation.vehicleType
                )).map((data, index) => (
                  <div className={styles["summary-tables-container"]} key={index}>
                    <ShippingResults
                      key={index}
                      routeData={data}
                      isTollsListOpen={openTollsList === index}
                      onToggle={() =>
                        setOpenTollsList((prev) =>
                          prev !== index ? index : null
                        )
                      }
                    />
                  </div>
                ))}
              </div>
              {
                <div className={styles["map-container"]}>
                  <Map routeData={routeData} />
                </div>
              }
              {isLoading && renderSpinner()}
            </div>
          ) : (
            <>
              <StoredRoutes
                areRoutesUpdated={areRoutesUpdated}
                setAreRoutesUpdated={setAreRoutesUpdated}
                setIsRouteStorageLoading={setIsLoading}
                storedRoutesList={storedRoutesList}
                setStoredRoutesList={setStoredRoutesList}
              />
              {isLoading && renderSpinner()}
            </>
          )}
        </div>
      </Context.Provider>
    </div>
  );
};

export default App;
