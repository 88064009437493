import {
  ComparisonResult,
  MaplinkApiResponse,
  VehicleType,
} from "../types/globalTypes";

export function calculateFreight(
  vehicle: VehicleType,
  distance: number | undefined
): number {
  const displacementCoefficients = {
    TRUCK_WITH_SIX_DOUBLE_AXLES: [5.9471, 381.28],
    TRUCK_WITH_NINE_DOUBLE_AXLES: [7.3701, 482.47],
  };

  if (!distance) return 0;

  const vehicleCoefficients = displacementCoefficients[vehicle];

  if (!vehicleCoefficients) return 0;

  // This calculation has hard-coded values from the ANTT table and "percentuais de eixos"
  const result =
    ((vehicleCoefficients[1] + vehicleCoefficients[0] * distance) * 1.37) /
    1000;

  return result;
}

export function areSameOriginAndDestination(
  prevData: MaplinkApiResponse | null,
  currentData: MaplinkApiResponse
) {
  if (!prevData || !currentData) return false;

  const prevStartPoint = prevData.route.legs[0].points[0];
  const prevEndPoint =
    prevData.route.legs[0].points[prevData.route.legs[0].points.length - 1];

  const currentStartPoint = currentData.route.legs[0].points[0];
  const currentEndPoint =
    currentData.route.legs[0].points[
      currentData.route.legs[0].points.length - 1
    ];
  const prevAxes = prevData.route.legs[0].tollCalculation.vehicleType;
  const currentAxes = currentData.route.legs[0].tollCalculation.vehicleType;

  return (
    prevStartPoint.latitude === currentStartPoint.latitude &&
    prevStartPoint.longitude === currentStartPoint.longitude &&
    prevEndPoint.latitude === currentEndPoint.latitude &&
    prevEndPoint.longitude === currentEndPoint.longitude &&
    prevAxes !== currentAxes
  );
}

export const getComparisonDetails = (
  current: number,
  previous: number
): ComparisonResult => {
  if (previous === 0) {
    return {
      direction: "none",
      color: "none",
    };
  }

  if (current > previous) {
    return {
      direction: "up",
      color: "red",
    };
  } else if (current < previous) {
    return {
      direction: "down",
      color: "green",
    };
  } else {
    return {
      direction: "none",
      color: "none",
    };
  }
};
