import { useEffect, useRef, useState } from "react";
import styles from "../../CustomForm.module.css";

interface DestinationInputProps {
  value: string;
  suggestions: any[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSuggestionClick: (lon: number, lat: number, label: string) => void;
  eraseSuggestions: () => void;
}

const DestinationInput: React.FC<DestinationInputProps> = ({
  value,
  suggestions,
  onChange,
  onSuggestionClick,
  eraseSuggestions,
}) => {
  const [shouldBoxAppear, setShouldBoxAppear] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      target &&
      !target.closest(`.${styles.suggestionsDropdown}`) &&
      target !== inputRef.current
    ) {
      setShouldBoxAppear(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={styles.destinationWrapper}>
        <input
          ref={inputRef}
          id="destination"
          name="destination"
          type="text"
          value={value}
          onChange={(e) => onChange(e)}
          onFocus={() => setShouldBoxAppear(true)}
          className={styles.destination}
          placeholder="Insira o destino desejado"
          required
        />
        {value && suggestions.length > 0 && (
          <span
            className={styles.clearButton}
            onClick={(e) => {
              e.stopPropagation();
              onChange({
                target: { value: "" },
              } as React.ChangeEvent<HTMLInputElement>);
              setShouldBoxAppear(false);
              eraseSuggestions();
            }}
          >
            ×
          </span>
        )}
      </div>

      {suggestions.length > 0 && shouldBoxAppear && (
        <div className={styles.suggestionsDropdown} id="suggestionsBox">
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.id || suggestion.label}
              className={styles.suggestionItem}
              onClick={(e) => {
                e.stopPropagation();
                const { lat, lon } = suggestion.address.mainLocation;
                onSuggestionClick(lon, lat, suggestion.label);

                setTimeout(() => {
                  inputRef.current?.blur();
                  setShouldBoxAppear(false);
                }, 0);
              }}
            >
              {suggestion.label}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DestinationInput;
