import axios from "axios";
import {
  MaplinkApiResponse,
  Point,
  RouteArgsForId,
} from "../types/globalTypes";

const BASE_API_URL =
  "https://ezolwvh33l.execute-api.us-east-1.amazonaws.com/LIVE";

export class ApiError extends Error {
  constructor(public message: string, public statusCode?: number) {
    super(message);
  }
}

const handleError = (error: any): void => {
  if (error.response) {
    throw new ApiError(
      error.response.data.message || "An error occurred.",
      error.response.status
    );
  } else if (error.request) {
    throw new ApiError(
      "No response received from the server. Try again later!"
    );
  } else {
    throw new ApiError(`${error.message} Try again later!`);
  }
};

export const getRouteFromMaplink = async (
  points: Point[],
  axesValue: string
) => {
  try {
    return await axios.get(`${BASE_API_URL}/calculate-shipping`, {
      params: {
        points: JSON.stringify(points),
        axes: axesValue,
      },
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAddressSuggestionsFromMaplink = async (query: string) => {
  try {
    return axios.get(`${BASE_API_URL}/address-suggestions`, {
      params: { query },
    });
  } catch (error) {
    handleError(error);
  }
};

export const getRouteIdForStoring = async (
  routeDetails: RouteArgsForId
): Promise<string> => {
  const apiUrl = `${BASE_API_URL}/route`;

  try {
    const response = await axios.post(apiUrl, routeDetails);

    if (response.data && response.data.id) {
      return response.data.id;
    } else {
      throw new ApiError("Invalid response format when posting route.");
    }
  } catch (error) {
    if (error instanceof Error) {
      throw new ApiError(`Error posting route: ${error.message}`);
    } else {
      throw new ApiError("An unexpected error occurred.");
    }
  }
};

export const storeRoute = async (
  routeId: string,
  routeData: MaplinkApiResponse | null
) => {
  const apiUrl = `${BASE_API_URL}/route/history`;

  try {
    const response = await axios.post(apiUrl, {
      routeId,
      response: routeData?.route,
    });

    if (response.data) {
      getAllRoutes();
      return response.data;
    } else {
      throw new ApiError("Invalid response format when storing route.");
    }
  } catch (error) {
    if (error instanceof Error) {
      throw new ApiError(`Error storing route: ${error.message}`);
    } else {
      throw new ApiError("An unexpected error occurred.");
    }
  }
};

export const getAllRoutes = async () => {
  const apiUrl = `${BASE_API_URL}/route`;

  try {
    const response = await axios.get(apiUrl);

    if (response.data) {
      return response.data;
    } else {
      throw new ApiError("Invalid response format when fetching routes.");
    }
  } catch (error) {
    if (error instanceof Error) {
      throw new ApiError(`Error fetching routes: ${error.message}`);
    } else {
      throw new ApiError("An unexpected error occurred.");
    }
  }
};

export const deleteRoute = async (id: string) => {
  try {
    const response = await axios.delete(`${BASE_API_URL}/route/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new ApiError(
        `Failed to delete route with status ${response.status}`
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      throw new ApiError(`Failed to delete route: ${error.message}`);
    } else {
      throw new ApiError("An unexpected error occurred.");
    }
  }
};
