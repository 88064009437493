import React from "react";
import styles from "./RouteDetailsModal.module.css";
import Map from "../Map/index";
import { MaplinkApiResponse } from "../../types/globalTypes";
import {
  formatDistance,
  formatOrigimName,
  formatPrice,
} from "../../utils/formatters";
import { calculateFreight, getComparisonDetails } from "../../utils/utils";

interface RouteDetailsModalProps {
  onClose: () => void;
  routeData: any;
  originAddress: string;
  destinationAddress: string;
}

export const RouteDetailsModal: React.FC<RouteDetailsModalProps> = ({
  onClose,
  routeData,
  originAddress,
  destinationAddress,
}) => {
  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const extractRouteData = (routeDatum: MaplinkApiResponse) => ({
    totalDistance: routeDatum.route.totalDistance,
    tollCosts: routeDatum.route.tollCosts,
    freightCosts: calculateFreight(
      routeDatum.route.legs[0].tollCalculation.vehicleType,
      routeDatum.route.totalDistance
    ),
  });

  const getColorClass = (current: number, previous: number) => {
    const comparisonResult = getComparisonDetails(current, previous);
    return styles[comparisonResult.color] || "";
  };

  const [route1, route2] = routeData;

  const route1Data = extractRouteData(route1);
  const route2Data = route2 ? extractRouteData(route2) : null;

  return (
    <div className={styles["modal-overlay"]} onClick={handleOutsideClick}>
      <div className={styles["modal-content"]}>
        <button onClick={onClose} className={styles["close-modal"]}>
          X
        </button>
        <div className={styles.header}>
          <div className={styles.subheader}>
            <div>Origem:</div>
            <div>{formatOrigimName(originAddress)}</div>
          </div>
          <div className={styles.subheader}>
            <div>Destino:</div>
            <div>{destinationAddress}</div>
          </div>
        </div>
        <Map routeData={routeData} style={{ height: 400, width: "auto" }} />
        <div className={styles["table-wrapper"]}>
          <table className={styles["comparison-table"]}>
            <thead>
              <tr>
                <th></th>
                <th>Rota mais recente</th>
                <th>Rota previamente cadastrada</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Distância</td>
                <td
                  className={getColorClass(
                    route1Data.totalDistance,
                    route2Data?.totalDistance || 0
                  )}
                >
                  {formatDistance(route1Data.totalDistance)}
                </td>
                <td>
                  {route2Data
                    ? formatDistance(route2Data.totalDistance)
                    : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Pedágio</td>
                <td
                  className={getColorClass(
                    route1Data.tollCosts,
                    route2Data?.tollCosts || 0
                  )}
                >
                  {formatPrice(route1Data.tollCosts)}
                </td>
                <td>
                  {route2Data ? formatPrice(route2Data.tollCosts) : "N/A"}
                </td>
              </tr>
              <tr>
                <td>Frete Total</td>
                <td
                  className={getColorClass(
                    route1Data.freightCosts,
                    route2Data?.freightCosts || 0
                  )}
                >
                  {formatPrice(route1Data.freightCosts)}
                </td>
                <td>
                  {route2Data ? formatPrice(route2Data.freightCosts) : "N/A"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
