import { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Popup,
} from "react-leaflet";
import { MaplinkApiResponse, TollLocation } from "../../types/globalTypes";
import { formatPrice } from "../../utils/formatters";
import styles from "./Map.module.css";
import L from "leaflet";
import { useMap } from "react-leaflet";
import Legends from "./components/Legends";

interface MapProps {
  routeData: MaplinkApiResponse[] | null;
  style?: React.CSSProperties;
}

const Map: React.FC<MapProps> = ({ routeData, style }) => {
  const [showTolls, setShowTolls] = useState(true);
  const [displayedAxes, setDisplayedAxes] = useState<string[]>([]);

  const CenterOnRoute: React.FC<{ routeData: MaplinkApiResponse[] | null }> = ({
    routeData,
  }) => {
    const map = useMap();

    useEffect(() => {
      if (!routeData) return;

      const latLngs: [number, number][] = routeData.flatMap((routeDatum) => {
        const points = routeDatum.route.legs[0].points;
        return [
          [points[0].latitude, points[0].longitude],
          [
            points[points.length - 1].latitude,
            points[points.length - 1].longitude,
          ],
        ];
      });

      if (latLngs.length === 0) return;

      const bounds = L.latLngBounds(latLngs);
      map.fitBounds(bounds);
    }, [routeData, map]);

    return null;
  };

  useEffect(() => {
    if (routeData) {
      const uniqueAxes = Array.from(
        new Set(
          routeData.map(
            (routeDatum) => routeDatum.route.legs[0].tollCalculation.vehicleType
          )
        )
      );
      setDisplayedAxes(uniqueAxes);
    }
  }, [routeData]);

  return (
    <MapContainer
      center={[-27.047456, -50.37972]}
      zoom={6}
      scrollWheelZoom={false}
      style={style || { width: "100%", maxHeight: "500px" }}
      className={styles["map-container"]}
    >
      <CenterOnRoute routeData={routeData} />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {routeData?.map((routeDatum, index) => {
        const routePoints = routeDatum.route.legs[0].points;
        const axes = routeDatum.route.legs[0].tollCalculation.vehicleType;

        return (
          <Polyline
            key={index}
            positions={routePoints.map((point) => [
              point.latitude,
              point.longitude,
            ])}
            color={
              axes === "TRUCK_WITH_SIX_DOUBLE_AXLES" ? "#eb6b54" : "#4275f5"
            }
          />
        );
      })}

      {showTolls &&
        routeData?.map((routeDatum) => {
          return routeDatum.route.legs[0].tollCalculation.tolls.map(
            (toll: TollLocation, tollIndex) => (
              <Marker
                key={tollIndex}
                position={[
                  toll.coordinates.latitude,
                  toll.coordinates.longitude,
                ]}
              >
                <Popup>
                  <strong>
                    {toll.name.replace("Pedágio - ", "")} -{" "}
                    {formatPrice(toll.price)}
                  </strong>
                </Popup>
              </Marker>
            )
          );
        })}
      {routeData && routeData?.length > 0 && (
        <label className={styles["tolls-toggle"]}>
          <input
            id="tolls-chackbox"
            name="tolls-chackbox"
            type="checkbox"
            checked={showTolls}
            onChange={() => setShowTolls(!showTolls)}
            className={styles.checkbox}
          />
          <div>Pedágios</div>
        </label>
      )}
      {routeData && routeData?.length > 0 && (
        <Legends displayedAxes={displayedAxes} />
      )}
    </MapContainer>
  );
};

export default Map;
