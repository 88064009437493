import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import pin from "../../assets/pin.png";
import {
  MaplinkApiResponse,
  Point,
  RouteArgsForId,
  Suggestion,
} from "../../types/globalTypes";
import Spinner from "../../utils/Spinner";
import {
  ApiError,
  getAddressSuggestionsFromMaplink,
  getAllRoutes,
  getRouteFromMaplink,
  getRouteIdForStoring,
  storeRoute,
} from "../../utils/api";
import { Context } from "../../utils/context";
import { areSameOriginAndDestination } from "../../utils/utils";
import AxesDropdown from "../CustomForm/components/AxesDropdown";
import DestinationInput from "../CustomForm/components/DestinationInput";
import OriginDropdown from "../CustomForm/components/OriginDropdown";
import ErrorModal from "../ErrorModal";
import styles from "./CustomForm.module.css";

interface CustomFormProps {
  formType: string;
  onRouteChange: (response: MaplinkApiResponse[]) => void;
  setAreRoutesUpdated: (value: boolean) => void;
  onStoredRouteSuccess?: () => void;
  onLoadingStart: () => void;
  onLoadingEnd: () => void;
}

const CustomForm: React.FC<CustomFormProps> = ({
  formType,
  onRouteChange,
  setAreRoutesUpdated,
  onStoredRouteSuccess,
  onLoadingStart,
  onLoadingEnd,
}) => {
  const [origin, setOrigin] = useState<string>("usina-cubatao");
  const [destination, setDestination] = useState<string>("");
  const [axes, setAxes] = useState<number>(6);
  const [tonnageLocal, setTonnageLocal] = useState<number>(0);
  const [client, setClient] = useState("");
  const { setTonnage } = useContext(Context);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [destinationLabel, setDestinationLabel] = useState<string>("");
  const [isSuggestionLoading, setSuggestionLoading] = useState(false);
  const isShippingForm = formType === "shipping";
  const [destLong, destLat] = destination.split(",").map(Number);
  const previousRouteData = useRef<MaplinkApiResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  const ORIGIN_MAP: Record<string, Point> = {
    "usina-cubatao": { latitude: -23.8529595, longitude: -46.3745231 },
    "usina-ipatinga": { latitude: -19.4951857, longitude: -42.5660322 },
  };

  const AXES_MAP: Record<number, string> = {
    6: "TRUCK_WITH_SIX_DOUBLE_AXLES",
    9: "TRUCK_WITH_NINE_DOUBLE_AXLES",
    0: "TRUCK_WITH_SIX_AND_NINE_DOUBLE_AXLES",
  };

  const fetchRouteData = async () => {
    try {
      if (axes === 0) {
        const response = await Promise.all([
          getRoute(origin, destination, 6),
          getRoute(origin, destination, 9)
        ]);

        return response;
      }

      return [await getRoute(origin, destination, axes)];
    } catch (error) {
      if (error instanceof Error) {
        setError(`${error.message} Try again later!`);
        onLoadingEnd();
      } else {
        setError("An unexpected error occurred. Try again later!");
      }
      throw error;
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    onLoadingStart();
    event.preventDefault();

    try {
      const routeDetails = buildRouteDetailsObject(
        origin,
        destinationLabel,
        axes
      );

      if (isShippingForm) {
        const fetchedRouteData = await fetchRouteData();

        if (fetchedRouteData.length > 1) {
          fetchedRouteData.forEach((route: any) => {
            if (
              areSameOriginAndDestination(
                previousRouteData.current,
                route
              )
            ) {
              if (previousRouteData.current) {
                onRouteChange([previousRouteData.current, route]);
              } else {
                onRouteChange([route]);
              }
            } else {
              onRouteChange([route]);
            }

            previousRouteData.current = route;
          })
        } else {
          onRouteChange(fetchedRouteData);
        }
      } else {
        await fetchAndStoreRoute(routeDetails);
        await getAllRoutes();
        setAreRoutesUpdated(true);
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setError(error.message);
      } else {
        setError("An unexpected error occurred. Try again later!");
      }
    } finally {
      onLoadingEnd();
      setTonnage(tonnageLocal);
    }
  };

  const buildRouteDetailsObject = (
    origin: string,
    destinationLabel: string,
    axes: number
  ): RouteArgsForId => {
    return {
      origin: {
        coord: `${ORIGIN_MAP[origin].latitude}, ${ORIGIN_MAP[origin].longitude}`,
        address: origin,
      },
      destination: {
        coord: `${destLong}, ${destLat}`,
        address: destinationLabel,
      },
      clientName: client || " ",
      truckAxles: axes,
    };
  };

  const getRoute = async (
    origin: string,
    destination: string,
    axes: number
  ): Promise<MaplinkApiResponse> => {
    const [destLong, destLat] = destination.split(",").map(Number);

    const points: Point[] = [
      {
        siteId: "1",
        ...ORIGIN_MAP[origin],
      },
      {
        siteId: "2",
        latitude: destLat,
        longitude: destLong,
      },
    ];

    const axesValue = AXES_MAP[axes] || "TRUCK_WITH_SIX_DOUBLE_AXLES";

    try {
      const response = await getRouteFromMaplink(points, axesValue);
      return response?.data;
    } catch (error) {
      if (error instanceof ApiError) {
        throw new Error(error.message);
      } else {
        throw new Error("An unexpected error occurred fetching the route.");
      }
    }
  };

  const getAddressSuggestions = async () => {
    try {
      if (destinationLabel.length >= 3) {
        setSuggestionLoading(true);
        const response = await getAddressSuggestionsFromMaplink(
          destinationLabel
        );
        setSuggestions(response?.data.results);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(`${error.message} Try again later!`);
      } else {
        setError("An unexpected error occurred. Try again later!");
      }
      setSuggestionLoading(false);
    }
  };

  const handleDestinationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setDestinationLabel(inputValue);

    if (inputValue.trim() === "") {
      setSuggestions([]);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      return;
    } else {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        getAddressSuggestions();
      }, 500);
    }
  };

  useEffect(() => {
    if (suggestions.length > 0) {
      setSuggestionLoading(false);
    }
  }, [suggestions]);

  useEffect(() => {
    setDestinationLabel("");
  }, [formType]);

  const fetchAndStoreRoute = async (routeDetails: RouteArgsForId) => {
    onLoadingStart();

    try {
      const fetchedRouteData = await getRoute(
        routeDetails.origin.address,
        routeDetails.destination.coord,
        routeDetails.truckAxles
      );

      const routeId = await getRouteIdForStoring(routeDetails);
      if (fetchedRouteData) {
        await storeRoute(routeId, fetchedRouteData);
        if (onStoredRouteSuccess) {
          onStoredRouteSuccess();
        }
      } else {
        console.error("Route data not available for storage.");
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setError(error.message);
      } else {
        setError("An unexpected error occurred. Try again later!");
      }
    }

    onLoadingEnd();
  };

  return (
    <form onSubmit={handleSubmit} className={styles[`container-storage`]}>
      <label className={styles[`input-box-origin-storage`]}>
        {isShippingForm && (
          <>
            <div className={styles.circle}></div>
            <div className={styles["mini-circle1"]}></div>
            <div className={styles["mini-circle2"]}></div>
            <div className={styles["mini-circle3"]}></div>
          </>
        )}
        <div className={styles["input-label"]}>Origem</div>
        <OriginDropdown value={origin} onChange={setOrigin} />
      </label>
      <label className={styles[`input-box-destination-storage`]}>
        <div className={styles["input-label"]}>Destino</div>
        {formType === "shipping" && (
          <img src={pin} alt="pin" className={styles.pin} />
        )}
        <DestinationInput
          value={destinationLabel}
          suggestions={suggestions}
          onChange={handleDestinationChange}
          onSuggestionClick={(lon, lat, label) => {
            setDestination(`${lon},${lat}`);
            setDestinationLabel(label);
          }}
          eraseSuggestions={() => setSuggestions([])}
        />
        {isSuggestionLoading && (
          <Spinner
            style={{
              position: "absolute",
              margin: "auto",
              display: "block",
              bottom: 8,
              right: 10,
            }}
            size={25}
          />
        )}
      </label>
      <label className={styles[`input-box-axes-storage`]}>
        <div className={styles["input-label"]}>Eixos</div>
        <AxesDropdown value={axes} onChange={setAxes} />
      </label>

      {isShippingForm && (
        <label className={styles[`input-box-tonnage`]}>
          <div className={styles["input-label"]}>Tonelagem</div>
          <input
            id="tonnage"
            name="tonnage"
            type="number"
            value={tonnageLocal}
            onChange={(e) => setTonnageLocal(Number(e.target.value))}
            className={styles.tonnage}
            placeholder="Insira a tonelagem"
          />
        </label>
      )}
      {!isShippingForm && (
        <label className={styles[`input-box-client`]}>
          <div className={styles["input-label"]}>Cliente</div>
          <input
            id="client"
            name="client"
            type="text"
            value={client}
            onChange={(e) => setClient(e.target.value)}
            className={styles.client}
            placeholder="Insira o nome do Cliente"
          />
        </label>
      )}
      <div className={styles[`button-container-storage`]}>
        <button
          type="submit"
          className={`${styles.button} ${styles[`storage-button`]}`}
        >
          {formType === "shipping" ? "Calcular" : "Cadastrar Rota"}
        </button>
      </div>
      {error && <ErrorModal message={error} onClose={() => setError(null)} />}
    </form>
  );
};

export default CustomForm;
