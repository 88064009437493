import styles from "./ErrorModal.module.css";

const ErrorModal = ({
  message,
  onClose,
}: {
  message: string;
  onClose: () => void;
}) => {
  return (
    <div className={styles["modal-overlay"]}>
      <div className={styles["modal-content"]}>
        <div className={styles["modal-header"]}>
          <h4>Error</h4>
        </div>
        <div className={styles["modal-body"]}>{message}</div>
        <div className={styles["modal-footer"]}>
          <button
            className={styles["error-button"]}
            onClick={() => {
              onClose();
              window.location.reload();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
