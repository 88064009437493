import { StoredRoutesResponse, VehicleType } from "../types/globalTypes";

export function formatDistance(distance: number): string {
  const inKilometers = distance / 1000;
  let distanceStr = inKilometers.toFixed(1).replace(".", ",");
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  distanceStr = distanceStr.replace(regex, ".");
  return `${distanceStr} km`;
}

export function formatTime(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}h ${formattedMinutes}min`;
}

export function formatPrice(price: number): string {
  let priceStr = price.toFixed(2).replace(".", ",");
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  priceStr = priceStr.replace(regex, ".");
  return `R$ ${priceStr}`;
}

export function axesToVehicleTypeConverter(numberOfAxes: number): VehicleType {
  if (numberOfAxes === 6) {
    return "TRUCK_WITH_SIX_DOUBLE_AXLES";
  } else if (numberOfAxes === 9) {
    return "TRUCK_WITH_NINE_DOUBLE_AXLES";
  } else {
    return "TRUCK_WITH_SIX_DOUBLE_AXLES";
  }
}

export function vehicleTypeToAxesConverter(
  vehicleType: string | undefined
): number {
  if (vehicleType === "TRUCK_WITH_SIX_DOUBLE_AXLES") {
    return 6;
  } else if (vehicleType === "TRUCK_WITH_NINE_DOUBLE_AXLES") {
    return 9;
  } else {
    return 6;
  }
}

export function formatOrigimName(name: string): string {
  if (name === "usina-cubatao") {
    return "Usina de Cubatão";
  } else if (name === "usina-ipatinga") {
    return "Usina de Ipatinga";
  }
  return name;
}

export function preprocessForExport(routesList: StoredRoutesResponse[]) {
  return routesList.map((route: any) => ({
    Origem: formatOrigimName(route.origin.address),
    Destino: route.destination.address,
    Cliente: route.clientName,
    Eixos: route.truckAxles,
    Distância: formatDistance(route.routeHistory?.[0]?.payload.totalDistance),
    Pedágio: formatPrice(route.routeHistory?.[0]?.payload.tollCosts || 0),
    Frete: formatPrice(route.freight || 0),
  }));
}
