import React from "react";
import styles from "./Legends.module.css";

interface LegendsProps {
  displayedAxes: string[];
}

const Legends: React.FC<LegendsProps> = ({ displayedAxes }) => {
  return (
    <div className={styles.legend}>
      {displayedAxes.includes("TRUCK_WITH_SIX_DOUBLE_AXLES") && (
        <div className={styles.legendItem}>
          <span
            className={styles.legendColor}
            style={{ backgroundColor: "#eb6b54" }}
          ></span>
          Seis Eixos
        </div>
      )}
      {displayedAxes.includes("TRUCK_WITH_NINE_DOUBLE_AXLES") && (
        <div className={styles.legendItem}>
          <span
            className={styles.legendColor}
            style={{ backgroundColor: "#4275f5" }}
          ></span>
          Nove Eixos
        </div>
      )}
    </div>
  );
};

export default Legends;
