import ClipLoader from "react-spinners/ClipLoader";

interface SpinnerProps {
  style: any;
  size: number;
}

const Spinner: React.FC<SpinnerProps> = ({ style, size }) => {
  return (
    <div style={style}>
      <ClipLoader color="#66cc33" size={size} />
    </div>
  );
};

export default Spinner;
