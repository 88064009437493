import logo from "../../assets/logo.png";
import styles from "./Header.module.css";

const Header: React.FC = () => {
  return (
    <header className={styles["header-container"]}>
      <img src={logo} alt="Company Logo" className={styles["logo-image"]} />
    </header>
  );
};

export default Header;
