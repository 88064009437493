import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  ComparisonResult,
  RouteHistory,
  StoredRoutesResponse,
} from "../../types/globalTypes";
import { ApiError, deleteRoute, getAllRoutes } from "../../utils/api";
import {
  axesToVehicleTypeConverter,
  formatDistance,
  formatOrigimName,
  formatPrice,
  preprocessForExport,
} from "../../utils/formatters";
import { calculateFreight, getComparisonDetails } from "../../utils/utils";
import ErrorModal from "../ErrorModal";
import { RouteDetailsModal } from "../RouteDetailsModal";
import styles from "./StoredRoutes.module.css";

interface StoredRoutesProps {
  areRoutesUpdated: boolean;
  setAreRoutesUpdated: (value: boolean) => void;
  setIsRouteStorageLoading: (value: boolean) => void;
  storedRoutesList: any[];
  setStoredRoutesList: (value: StoredRoutesResponse[]) => void;
}

const StoredRoutes: React.FC<StoredRoutesProps> = ({
  areRoutesUpdated,
  setAreRoutesUpdated,
  setIsRouteStorageLoading,
  storedRoutesList,
  setStoredRoutesList,
}) => {
  const [selectedRouteIndex, setSelectedRouteIndex] = useState<number | null>(
    null
  );
  const [selectedRouteAddresses, setSelectedRouteAddresses] = useState<{
    originAddress: string;
    destinationAddress: string;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);

  const openModalForRoute = (index: number) => {
    setSelectedRouteIndex(index);
    setSelectedRouteAddresses(
      extractOriginAndDestinationAddresses(storedRoutesList[index])
    );
  };

  const closeModal = () => {
    setSelectedRouteIndex(null);
  };

  useEffect(() => {
    const fetchRoutes = async () => {
      setError(null);
      try {
        setIsRouteStorageLoading(true);
        const routes = await getAllRoutes();
        if (routes) {
          const preparedRoutes = routes.data.map(
            (route: StoredRoutesResponse) => ({
              ...route,
              freight: calculateFreight(
                axesToVehicleTypeConverter(route.truckAxles),
                route?.routeHistory?.[0]?.payload.totalDistance
              ),
            })
          );
          setStoredRoutesList(preparedRoutes);
          setIsRouteStorageLoading(false);
          setAreRoutesUpdated(false);
        }
      } catch (error) {
        if (error instanceof ApiError) {
          setError(error.message);
        } else {
          setError("Failed to fetch routes. Try again later!");
        }
        setIsRouteStorageLoading(false);
      }
    };

    if (areRoutesUpdated || storedRoutesList.length === 0) {
      fetchRoutes();
    }
  }, [areRoutesUpdated]);

  const exportToExcel = () => {
    const processedData = preprocessForExport(storedRoutesList);
    const ws = XLSX.utils.json_to_sheet(processedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Routes");
    XLSX.writeFile(wb, "routes.xlsx");
  };

  function extractOriginAndDestinationAddresses(route: StoredRoutesResponse) {
    return {
      originAddress: route.origin.address,
      destinationAddress: route.destination.address,
    };
  }

  const getRouteComparisons = (route: StoredRoutesResponse) => {
    const currentToll = route.routeHistory?.[0]?.payload.tollCosts || 0;
    const previousToll = route.routeHistory?.[1]?.payload.tollCosts || 0;
    const currentDistance = route.routeHistory?.[0]?.payload.totalDistance || 0;
    const previousDistance =
      route.routeHistory?.[1]?.payload.totalDistance || 0;
    const currentFreight = route.freight || 0;

    console.log('route.routeHistory?.[1]?.payload.legs:', route.routeHistory[0].payload.legs);

    const previousFreight = calculateFreight(
      route.routeHistory?.[0]?.payload.legs[0].tollCalculation.vehicleType,
      route.routeHistory?.[0]?.payload.totalDistance
    );

    return {
      tollComparison: getComparisonDetails(currentToll, previousToll),
      distanceComparison: getComparisonDetails(
        currentDistance,
        previousDistance
      ),
      freightComparison: getComparisonDetails(currentFreight, previousFreight),
    };
  };

  const ComparisonArrow = ({
    comparison,
  }: {
    comparison: ComparisonResult;
  }) => {
    if (comparison.direction === "none") return null;
    return (
      <span
        className={`${styles.arrow} ${styles[comparison.color]} ${
          styles[comparison.direction]
        }`}
      ></span>
    );
  };

  const handleDeleteRoute = async (routeId: string) => {
    setError(null);
    try {
      setIsRouteStorageLoading(true);

      await deleteRoute(routeId);
      const routes = await getAllRoutes();

      if (routes) {
        const preparedRoutes = routes.data.map(
          (route: StoredRoutesResponse) => ({
            ...route,
            freight: calculateFreight(
              axesToVehicleTypeConverter(route.truckAxles),
              route?.routeHistory?.[0]?.payload.totalDistance
            ),
          })
        );
        setStoredRoutesList(preparedRoutes);
      }
    } catch (error) {
      if (error instanceof ApiError) {
        setError(error.message);
      } else {
        setError("Failed to delete and fetch updated routes. Try again later!");
      }
    } finally {
      setIsRouteStorageLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <table className={styles["stored-routes-table"]}>
        <thead>
          <tr>
            <th>Origem</th>
            <th>Destino</th>
            <th>Cliente</th>
            <th>Eixos</th>
            <th>Distância</th>
            <th>Pedágio</th>
            <th>Frete</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {storedRoutesList.map((route, index) => {
            const comparisons = getRouteComparisons(route);

            return (
              <React.Fragment key={index}>
                <tr>
                  <td>{formatOrigimName(route.origin.address)}</td>
                  <td>{route.destination.address}</td>
                  <td>{route.clientName || "teste"}</td>
                  <td>{route.truckAxles}</td>
                  <td>
                    {formatDistance(
                      route.routeHistory?.[0]?.payload.totalDistance
                    )}
                    <ComparisonArrow
                      comparison={comparisons.distanceComparison}
                    />
                  </td>
                  <td>
                    {formatPrice(
                      route.routeHistory?.[0]?.payload.tollCosts || 0
                    )}
                    <ComparisonArrow comparison={comparisons.tollComparison} />
                  </td>
                  <td>
                    {formatPrice(route.freight || 0)}
                    <ComparisonArrow
                      comparison={comparisons.freightComparison}
                    />
                  </td>
                  <td
                    className={styles["eye-icon"]}
                    data-tooltip="View map"
                    onClick={() => openModalForRoute(index)}
                  ></td>
                  <td
                    className={styles["delete-icon"]}
                    data-tooltip="Delete route"
                    onClick={() => handleDeleteRoute(route.id)}
                  ></td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {selectedRouteIndex !== null && (
        <RouteDetailsModal
          onClose={closeModal}
          routeData={storedRoutesList[selectedRouteIndex].routeHistory.map(
            (historyItem: RouteHistory) => ({ route: historyItem.payload })
          )}
          originAddress={selectedRouteAddresses?.originAddress || ""}
          destinationAddress={selectedRouteAddresses?.destinationAddress || ""}
        />
      )}
      <div className={styles["button-container"]}>
        <button onClick={exportToExcel} className={styles["button"]}>
          Export to Excel
        </button>
      </div>
      {error && <ErrorModal message={error} onClose={() => setError(null)} />}
    </div>
  );
};

export default StoredRoutes;
