export interface MaplinkApiResponse {
  route: RouteData;
}

export interface RouteData {
  id: string;
  legs: Leg[];
  totalDistance: number;
  totalNominalDuration: number;
  tollCosts: number;
}

export interface Leg {
  distance: number;
  points: Point[];
  tollCalculation: TollCalculation;
}

export interface TollCalculation {
  legTotalCost: number;
  tolls: TollLocation[];
  vehicleType: VehicleType;
}

export interface TollLocation {
  id: string;
  name: string;
  price: number;
  address: string;
  city: string;
  state: {
    name: string;
    code: string;
  };
  coordinates: Point;
  serviceTypes: ServiceTypes[];
}

export interface ServiceTypes {
  name: string;
  serviceId: string;
}

export type VehicleType =
  | "TRUCK_WITH_SIX_DOUBLE_AXLES"
  | "TRUCK_WITH_NINE_DOUBLE_AXLES";

export interface Point {
  siteId?: string;
  latitude: number;
  longitude: number;
}

export interface Suggestion {
  address: {
    mainLocation: {
      lat: number;
      lon: number;
    };
  };
  label: string;
}

export enum CurrentViewType {
  SHIPPING = "shipping",
  STORAGE = "storage",
}

export interface RouteArgsForId {
  origin: {
    coord: string;
    address: string;
  };
  destination: {
    coord: string;
    address: string;
  };
  clientName: string;
  truckAxles: number;
}

export interface StoredRoutesResponse {
  origin: {
    coord: string;
    address: string;
  };
  destination: {
    coord: string;
    address: string;
  };
  updatedAt: string;
  createdAt: string;
  truckAxles: number;
  routeHistory: RouteHistory[];
  freight?: number;
  clientName?: string;

  SK: string;
  description: string;
  id: string;
  PK: string;
}

export interface RouteHistory {
  id: string;
  routeId: string;
  payload: {
    id: string;
    clientId: string;
    totalDistance: number;
    totalNominalDuration: number;
    averageSpeed: number;
    tollCosts: number;
    legs: Leg[];
    source: string;
    createdAt: number;
    expiryIn: number;
  };
}

export type ComparisonResult = {
  direction: "up" | "down" | "none";
  color: "green" | "red" | "none";
};
