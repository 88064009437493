import styles from "../../CustomForm.module.css";

interface OriginDropdownProps {
  value: string;
  onChange: (value: string) => void;
}

const OriginDropdown: React.FC<OriginDropdownProps> = ({ value, onChange }) => {
  return (
    <select
      id="origin"
      name="origin"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={styles.origin}
    >
      <option value="usina-cubatao">Usina de Cubatão</option>
      <option value="usina-ipatinga">Usina de Ipatinga</option>
    </select>
  );
};

export default OriginDropdown;
